const initial = {
    "id": "dGVybToxNA==",
    "databaseId": 14,
    "name": "Sitemap",
    "slug": "sitemap",
    "count": 102,
    "menuItems": {
        "nodes": [
            {
                "id": "cG9zdDo1MjM=",
                "label": "Home",
                "parentId": null,
                "path": "/home/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1MjM=",
                "connectedNode": {
                    "node": {
                        "uri": "/home/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MjI=",
                "label": "First Time Buyer",
                "parentId": null,
                "path": "/first-time-buyer/",
                "title": null,
                "target": null,
                "cssClasses": [
                    "services"
                ],
                "key": "cG9zdDo1MjI=",
                "connectedNode": {
                    "node": {
                        "uri": "/first-time-buyer/"
                    }
                }
            },
            {
                "id": "cG9zdDo1Mjc=",
                "label": "Remortgaging",
                "parentId": null,
                "path": "/remortgaging/",
                "title": null,
                "target": null,
                "cssClasses": [
                    "services"
                ],
                "key": "cG9zdDo1Mjc=",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgaging/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MTc=",
                "label": "Buy to let",
                "parentId": null,
                "path": "/buy-to-let/",
                "title": null,
                "target": null,
                "cssClasses": [
                    "services"
                ],
                "key": "cG9zdDo1MTc=",
                "connectedNode": {
                    "node": {
                        "uri": "/buy-to-let/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MjQ=",
                "label": "Home Mover",
                "parentId": null,
                "path": "/home-mover/",
                "title": null,
                "target": null,
                "cssClasses": [
                    "services"
                ],
                "key": "cG9zdDo1MjQ=",
                "connectedNode": {
                    "node": {
                        "uri": "/home-mover/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MjU=",
                "label": "Insurance",
                "parentId": null,
                "path": "/protection-needs/",
                "title": null,
                "target": null,
                "cssClasses": [
                    "services"
                ],
                "key": "cG9zdDo1MjU=",
                "connectedNode": {
                    "node": {
                        "uri": "/protection-needs/"
                    }
                }
            },
            {
                "id": "cG9zdDoxMDA3",
                "label": "Deals",
                "parentId": null,
                "path": "/rates/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMDA3",
                "connectedNode": {
                    "node": {
                        "uri": "/rates/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MTg=",
                "label": "Mortgage Calculators",
                "parentId": null,
                "path": "/calculators/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1MTg=",
                "connectedNode": {
                    "node": {
                        "uri": "/calculators/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MTY=",
                "label": "Marianna Financial Services",
                "parentId": null,
                "path": "/about-us/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1MTY=",
                "connectedNode": {
                    "node": {
                        "uri": "/about-us/"
                    }
                }
            },
            {
                "id": "cG9zdDo1Mjg=",
                "label": "Useful Information",
                "parentId": null,
                "path": "/useful-information/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1Mjg=",
                "connectedNode": {
                    "node": {
                        "uri": "/useful-information/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MjY=",
                "label": "Privacy Notice",
                "parentId": null,
                "path": "/privacy-notice/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1MjY=",
                "connectedNode": {
                    "node": {
                        "uri": "/privacy-notice/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MjE=",
                "label": "Cookie Policy",
                "parentId": null,
                "path": "/cookie-policy/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1MjE=",
                "connectedNode": {
                    "node": {
                        "uri": "/cookie-policy/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MTk=",
                "label": "Complaints Procedure",
                "parentId": null,
                "path": "/complaints-procedure/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1MTk=",
                "connectedNode": {
                    "node": {
                        "uri": "/complaints-procedure/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MjA=",
                "label": "Contact Us",
                "parentId": null,
                "path": "/contact/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1MjA=",
                "connectedNode": {
                    "node": {
                        "uri": "/contact/"
                    }
                }
            },
            {
                "id": "cG9zdDoxMjEy",
                "label": "Client Feedback",
                "parentId": null,
                "path": "/client-reviews/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMjEy",
                "connectedNode": {
                    "node": {
                        "uri": "/client-reviews/"
                    }
                }
            },
            {
                "id": "cG9zdDoxMjc0",
                "label": "Join Us",
                "parentId": null,
                "path": "/join-us/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMjc0",
                "connectedNode": {
                    "node": {
                        "uri": "/join-us/"
                    }
                }
            },
            {
                "id": "cG9zdDoxMzQ0",
                "label": "Ealing",
                "parentId": null,
                "path": "/mortgage-broker-ealing/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxMzQ0",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-ealing/"
                    }
                }
            },
            {
                "id": "cG9zdDoxNDk2",
                "label": "London",
                "parentId": null,
                "path": "/mortgage-broker-london/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxNDk2",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-london/"
                    }
                }
            },
            {
                "id": "cG9zdDoxNTE1",
                "label": "Brent",
                "parentId": null,
                "path": "/mortgage-broker-brent/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxNTE1",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-brent/"
                    }
                }
            },
            {
                "id": "cG9zdDoxNTE2",
                "label": "Kingston",
                "parentId": null,
                "path": "/mortgage-broker-kingston/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxNTE2",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-kingston/"
                    }
                }
            },
            {
                "id": "cG9zdDoxNTE3",
                "label": "Harrow",
                "parentId": null,
                "path": "/mortgage-broker-harrow/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxNTE3",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-harrow/"
                    }
                }
            },
            {
                "id": "cG9zdDoxNTE4",
                "label": "Hounslow",
                "parentId": null,
                "path": "/mortgage-broker-hounslow/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxNTE4",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-hounslow/"
                    }
                }
            },
            {
                "id": "cG9zdDoxNTE5",
                "label": "Slough",
                "parentId": null,
                "path": "/mortgage-broker-slough/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxNTE5",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-slough/"
                    }
                }
            },
            {
                "id": "cG9zdDoxNTIw",
                "label": "Richmond",
                "parentId": null,
                "path": "/mortgage-broker-richmond/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxNTIw",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-richmond/"
                    }
                }
            },
            {
                "id": "cG9zdDoxNTI1",
                "label": "Terms Of Business",
                "parentId": null,
                "path": "/terms-of-business/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoxNTI1",
                "connectedNode": {
                    "node": {
                        "uri": "/terms-of-business/"
                    }
                }
            },
            {
                "id": "cG9zdDoyMTg5",
                "label": "Mortgage Guides",
                "parentId": null,
                "path": "/mortgage-guide-home/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMTg5",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-guide-home/"
                    }
                }
            },
            {
                "id": "cG9zdDoyMTkw",
                "label": "Remortgage cost and savings guide",
                "parentId": null,
                "path": "/remortgage-cost-and-savings/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMTkw",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgage-cost-and-savings/"
                    }
                }
            },
            {
                "id": "cG9zdDoyMTkx",
                "label": "Home Mover Guide",
                "parentId": null,
                "path": "/home-mover-guide/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMTkx",
                "connectedNode": {
                    "node": {
                        "uri": "/home-mover-guide/"
                    }
                }
            },
            {
                "id": "cG9zdDoyMTky",
                "label": "Agreement in principle",
                "parentId": null,
                "path": "/agreement-in-principle/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMTky",
                "connectedNode": {
                    "node": {
                        "uri": "/agreement-in-principle/"
                    }
                }
            },
            {
                "id": "cG9zdDoyMTkz",
                "label": "No Fees Mortgage Broker",
                "parentId": null,
                "path": "/no-fees-mortgage-broker/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMTkz",
                "connectedNode": {
                    "node": {
                        "uri": "/no-fees-mortgage-broker/"
                    }
                }
            },
            {
                "id": "cG9zdDoyMTk0",
                "label": "How To Remortgage",
                "parentId": null,
                "path": "/remortgaging-guide/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMTk0",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgaging-guide/"
                    }
                }
            },
            {
                "id": "cG9zdDoyMTk1",
                "label": "Buy to Let Guide",
                "parentId": null,
                "path": "/buy-to-let-guide/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMTk1",
                "connectedNode": {
                    "node": {
                        "uri": "/buy-to-let-guide/"
                    }
                }
            },
            {
                "id": "cG9zdDoyMTk2",
                "label": "First Time Buyer Guide",
                "parentId": null,
                "path": "/first-time-buyer-guide/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDoyMTk2",
                "connectedNode": {
                    "node": {
                        "uri": "/first-time-buyer-guide/"
                    }
                }
            },
            {
                "id": "cG9zdDozMjY3",
                "label": "Croydon",
                "parentId": null,
                "path": "/mortgage-broker-croydon/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozMjY3",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-croydon/"
                    }
                }
            },
            {
                "id": "cG9zdDozMzAw",
                "label": "Watford",
                "parentId": null,
                "path": "/mortgage-broker-watford/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozMzAw",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-watford/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDU4",
                "label": "Berkshire",
                "parentId": null,
                "path": "/mortgage-broker-berkshire/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDU4",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-berkshire/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDU5",
                "label": "Hackney",
                "parentId": null,
                "path": "/mortgage-broker-hackney/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDU5",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-hackney/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDYw",
                "label": "Greenwich",
                "parentId": null,
                "path": "/mortgage-broker-greenwich/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDYw",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-greenwich/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDYx",
                "label": "Bexley",
                "parentId": null,
                "path": "/mortgage-broker-bexley/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDYx",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-bexley/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDYy",
                "label": "Reading",
                "parentId": null,
                "path": "/mortgage-broker-reading/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDYy",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-reading/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDYz",
                "label": "Surrey",
                "parentId": null,
                "path": "/mortgage-broker-surrey/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDYz",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-surrey/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDY0",
                "label": "Barnet",
                "parentId": null,
                "path": "/mortgage-broker-barnet/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDY0",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-barnet/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDY1",
                "label": "Enfield",
                "parentId": null,
                "path": "/mortgage-broker-enfield/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDY1",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-enfield/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDY2",
                "label": "Barking",
                "parentId": null,
                "path": "/mortgage-broker-barking/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDY2",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-barking/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDY3",
                "label": "Wandsworth",
                "parentId": null,
                "path": "/mortgage-broker-wandsworth/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDY3",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-wandsworth/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDY4",
                "label": "Guilford",
                "parentId": null,
                "path": "/mortgage-broker-guilford/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDY4",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-guilford/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDY5",
                "label": "Bromley",
                "parentId": null,
                "path": "/mortgage-broker-bromley/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDY5",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-bromley/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDcw",
                "label": "Redbridge",
                "parentId": null,
                "path": "/mortgage-broker-redbridge/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDcw",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-redbridge/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDcx",
                "label": "East Ham",
                "parentId": null,
                "path": "/mortgage-broker-east-ham/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDcx",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-east-ham/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDcy",
                "label": "Camden",
                "parentId": null,
                "path": "/mortgage-broker-camden/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDcy",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-camden/"
                    }
                }
            },
            {
                "id": "cG9zdDozNDky",
                "label": "Mortgage FAQs",
                "parentId": null,
                "path": "/mortgage-faqs/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNDky",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-faqs/"
                    }
                }
            },
            {
                "id": "cG9zdDozNTQz",
                "label": "Hammersmith",
                "parentId": null,
                "path": "/mortgage-broker-hammersmith/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNTQz",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-hammersmith/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQw",
                "label": "About buy-to-let things you should know",
                "parentId": null,
                "path": "/about-buy-to-let-things-you-should-know/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQw",
                "connectedNode": {
                    "node": {
                        "uri": "/about-buy-to-let-things-you-should-know/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQx",
                "label": "Are you mortgage-ready ?",
                "parentId": null,
                "path": "/are-you-mortgage-ready/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQx",
                "connectedNode": {
                    "node": {
                        "uri": "/are-you-mortgage-ready/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQy",
                "label": "First Time Buyer Guide",
                "parentId": null,
                "path": "/first-time-buyer-guide/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQy",
                "connectedNode": {
                    "node": {
                        "uri": "/first-time-buyer-guide/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQz",
                "label": "First Time Buyers Helpful Tips",
                "parentId": null,
                "path": "/first-time-buyers-tips/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQz",
                "connectedNode": {
                    "node": {
                        "uri": "/first-time-buyers-tips/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQ0",
                "label": "Guide to buying a leasehold property",
                "parentId": null,
                "path": "/guide-to-buying-aleasehold-property/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQ0",
                "connectedNode": {
                    "node": {
                        "uri": "/guide-to-buying-aleasehold-property/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQ1",
                "label": "Guide to Gifted Deposit",
                "parentId": null,
                "path": "/guide-to-gifted-deposit/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQ1",
                "connectedNode": {
                    "node": {
                        "uri": "/guide-to-gifted-deposit/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQ2",
                "label": "Higher Interest Rates And Remortgaging Options",
                "parentId": null,
                "path": "/higher-interest-rates-and-remortgaging-options/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQ2",
                "connectedNode": {
                    "node": {
                        "uri": "/higher-interest-rates-and-remortgaging-options/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQ3",
                "label": "How long it takes to remortgage",
                "parentId": null,
                "path": "/how-long-it-takes-to-remortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQ3",
                "connectedNode": {
                    "node": {
                        "uri": "/how-long-it-takes-to-remortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQ4",
                "label": "How To Staircase Shared Ownership?",
                "parentId": null,
                "path": "/how-to-staircase-shared-ownership/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQ4",
                "connectedNode": {
                    "node": {
                        "uri": "/how-to-staircase-shared-ownership/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjQ5",
                "label": "Should I Remortgage with same lender?",
                "parentId": null,
                "path": "/should-i-remortgage-with-same-lender/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjQ5",
                "connectedNode": {
                    "node": {
                        "uri": "/should-i-remortgage-with-same-lender/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjUw",
                "label": "Leasehold property guide",
                "parentId": null,
                "path": "/leasehold-property-guide/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjUw",
                "connectedNode": {
                    "node": {
                        "uri": "/leasehold-property-guide/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjUx",
                "label": "Leasehold Vs Freehold",
                "parentId": null,
                "path": "/leasehold-vs-freehold/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjUx",
                "connectedNode": {
                    "node": {
                        "uri": "/leasehold-vs-freehold/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjUy",
                "label": "Mortgage Jargon explained",
                "parentId": null,
                "path": "/mortgage-jargon-explained/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjUy",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-jargon-explained/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjUz",
                "label": "Mortgages facts good to know",
                "parentId": null,
                "path": "/facts-about-mortgages/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjUz",
                "connectedNode": {
                    "node": {
                        "uri": "/facts-about-mortgages/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjU0",
                "label": "NHS Mortgages",
                "parentId": null,
                "path": "/nhs-mortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjU0",
                "connectedNode": {
                    "node": {
                        "uri": "/nhs-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjU1",
                "label": "Remortgage cost and savings guide",
                "parentId": null,
                "path": "/remortgage-cost-and-savings/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjU1",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgage-cost-and-savings/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjU2",
                "label": "Remortgage for home improvements",
                "parentId": null,
                "path": "/remortgage-for-home-improvements/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjU2",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgage-for-home-improvements/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjU3",
                "label": "Remortgage with bad credit",
                "parentId": null,
                "path": "/remortgage-with-bad-credit/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjU3",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgage-with-bad-credit/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjU4",
                "label": "Right to buy mortgage",
                "parentId": null,
                "path": "/right-to-buy-mortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjU4",
                "connectedNode": {
                    "node": {
                        "uri": "/right-to-buy-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjU5",
                "label": "Self-employed Mortgage Broker",
                "parentId": null,
                "path": "/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjU5",
                "connectedNode": null
            },
            {
                "id": "cG9zdDozNjYw",
                "label": "Shared ownership mortgages",
                "parentId": null,
                "path": "/shared-ownership-mortgages/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjYw",
                "connectedNode": {
                    "node": {
                        "uri": "/shared-ownership-mortgages/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjYx",
                "label": "Tenants In Common” And “Joint Tenants",
                "parentId": null,
                "path": "/tenants-in-common-and-joint-tenants/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjYx",
                "connectedNode": {
                    "node": {
                        "uri": "/tenants-in-common-and-joint-tenants/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjYy",
                "label": "What can stop you getting mortgage",
                "parentId": null,
                "path": "/what-can-stop-you-getting-mortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjYy",
                "connectedNode": {
                    "node": {
                        "uri": "/what-can-stop-you-getting-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjYz",
                "label": "What documents required to Remortgage?",
                "parentId": null,
                "path": "/what-documents-required-to-remortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjYz",
                "connectedNode": {
                    "node": {
                        "uri": "/what-documents-required-to-remortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjY0",
                "label": "What is a buy-to-let remortgage?",
                "parentId": null,
                "path": "/what-is-a-buy-to-let-remortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjY0",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-a-buy-to-let-remortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjY1",
                "label": "What is a credit score?",
                "parentId": null,
                "path": "/what-is-a-credit-score/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjY1",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-a-credit-score/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjY2",
                "label": "What is a fixed-rate Mortgage?",
                "parentId": null,
                "path": "/what-is-a-fixed-rate-mortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjY2",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-a-fixed-rate-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjY3",
                "label": "What is a transfer of equity?",
                "parentId": null,
                "path": "/what-is-a-transfer-of-equity/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjY3",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-a-transfer-of-equity/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjY4",
                "label": "What is a variable rate mortgage?",
                "parentId": null,
                "path": "/what-is-a-variable-rate-mortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjY4",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-a-variable-rate-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjY5",
                "label": "What is an EPC",
                "parentId": null,
                "path": "/what-is-an-epc/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjY5",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-an-epc/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjcw",
                "label": "What is APRC?",
                "parentId": null,
                "path": "/what-is-aprc/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjcw",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-aprc/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjcx",
                "label": "What Is Conveyancing",
                "parentId": null,
                "path": "/what-is-conveyancing/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjcx",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-conveyancing/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjcy",
                "label": "What is green Mortgage?",
                "parentId": null,
                "path": "/what-is-green-mortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjcy",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-green-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjcz",
                "label": "What is loan to Value",
                "parentId": null,
                "path": "/what-is-loan-to-value/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjcz",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-loan-to-value/"
                    }
                }
            },
            {
                "id": "cG9zdDozNjc0",
                "label": "What is offset mortgage",
                "parentId": null,
                "path": "/what-is-offset-mortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDozNjc0",
                "connectedNode": {
                    "node": {
                        "uri": "/what-is-offset-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDo1MzIy",
                "label": "Contact Mandar",
                "parentId": null,
                "path": "/contact-mandar/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1MzIy",
                "connectedNode": {
                    "node": {
                        "uri": "/contact-mandar/"
                    }
                }
            },
            {
                "id": "cG9zdDo1NzQy",
                "label": "Buy to Let Deals",
                "parentId": null,
                "path": "/buy-to-let-deals/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1NzQy",
                "connectedNode": {
                    "node": {
                        "uri": "/buy-to-let-deals/"
                    }
                }
            },
            {
                "id": "cG9zdDo1NzQz",
                "label": "Home Mover Deals",
                "parentId": null,
                "path": "/home-mover-deals/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1NzQz",
                "connectedNode": {
                    "node": {
                        "uri": "/home-mover-deals/"
                    }
                }
            },
            {
                "id": "cG9zdDo1NzQ0",
                "label": "Remortgage Rates Compare",
                "parentId": null,
                "path": "/remortgages/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1NzQ0",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgages/"
                    }
                }
            },
            {
                "id": "cG9zdDo1NzQ1",
                "label": "FTB  Rate Compare",
                "parentId": null,
                "path": "/mortgages-first-time-buyer/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1NzQ1",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgages-first-time-buyer/"
                    }
                }
            },
            {
                "id": "cG9zdDo1ODI3",
                "label": "Santander Mortgage Rates",
                "parentId": null,
                "path": "/santandar-mortgages-rates/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1ODI3",
                "connectedNode": {
                    "node": {
                        "uri": "/santandar-mortgages-rates/"
                    }
                }
            },
            {
                "id": "cG9zdDo1ODU0",
                "label": "Buy to Let Deals",
                "parentId": null,
                "path": "/buy-to-let-deals/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1ODU0",
                "connectedNode": {
                    "node": {
                        "uri": "/buy-to-let-deals/"
                    }
                }
            },
            {
                "id": "cG9zdDo1ODU1",
                "label": "Home Mover Deals",
                "parentId": null,
                "path": "/home-mover-deals/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1ODU1",
                "connectedNode": {
                    "node": {
                        "uri": "/home-mover-deals/"
                    }
                }
            },
            {
                "id": "cG9zdDo1ODU2",
                "label": "Remortgage Rates Compare",
                "parentId": null,
                "path": "/remortgages/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1ODU2",
                "connectedNode": {
                    "node": {
                        "uri": "/remortgages/"
                    }
                }
            },
            {
                "id": "cG9zdDo1ODU3",
                "label": "FTB  Rate Compare",
                "parentId": null,
                "path": "/mortgages-first-time-buyer/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1ODU3",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgages-first-time-buyer/"
                    }
                }
            },
            {
                "id": "cG9zdDo1ODYy",
                "label": "reliance First time rates",
                "parentId": null,
                "path": "/reliance-first-time-rates/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1ODYy",
                "connectedNode": {
                    "node": {
                        "uri": "/reliance-first-time-rates/"
                    }
                }
            },
            {
                "id": "cG9zdDo1ODk4",
                "label": "Santander Mortgage Rates",
                "parentId": null,
                "path": "/santandar-mortgages-rates/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo1ODk4",
                "connectedNode": {
                    "node": {
                        "uri": "/santandar-mortgages-rates/"
                    }
                }
            },
            {
                "id": "cG9zdDo2MDQx",
                "label": "Halifax 2 Years Fixed Rate Mortgage",
                "parentId": null,
                "path": "/halifax-2-years-fixed-rate-mortgage/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo2MDQx",
                "connectedNode": {
                    "node": {
                        "uri": "/halifax-2-years-fixed-rate-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDo2Mjg5",
                "label": "Mortgage Broker Charges.",
                "parentId": null,
                "path": "/mortgage-broker-fees/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo2Mjg5",
                "connectedNode": {
                    "node": {
                        "uri": "/mortgage-broker-fees/"
                    }
                }
            },
            {
                "id": "cG9zdDo2MzE3",
                "label": "Halifax Product Transfer",
                "parentId": null,
                "path": "/halifax-product-transfer/",
                "title": null,
                "target": null,
                "cssClasses": [],
                "key": "cG9zdDo2MzE3",
                "connectedNode": {
                    "node": {
                        "uri": "/halifax-product-transfer/"
                    }
                }
            },
            {
                "id": "cG9zdDo2MzE4",
                "path": "/compare-remortgage-rates/",
                "connectedNode": {
                    "node": {
                        "uri": "/compare-remortgage-rates/"
                    }
                }
            },
            {
                "id": "cG9zdDo2MzE5",
                "path": "/first-time-buyer-mortgage-rates/",
                "connectedNode": {
                    "node": {
                        "uri": "/first-time-buyer-mortgage-rates/"
                    }
                }
            },
            {
                "id": "cG9zdDo2MzE6",
                "path": "/santander-fixed-rate-mortgage/",
                "connectedNode": {
                    "node": {
                        "uri": "/santander-fixed-rate-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDo2MzE8",
                "path": "/HSBC-mortgage-rates/",
                "connectedNode": {
                    "node": {
                        "uri": "/HSBC-mortgage-rates/"
                    }
                }
            },
            {
                "id": "cG9zdDo2MzE9",
                "path": "/nationwide-helping-hand-mortgage/",
                "connectedNode": {
                    "node": {
                        "uri": "/nationwide-helping-hand-mortgage/"
                    }
                }
            },
            {
                "id": "cG9zdDo2MzEa",
                "path": "/santandar-remortgages-guide/",
                "connectedNode": {
                    "node": {
                        "uri": "/santandar-remortgages-guide/"
                    }
                }
            }
        ]
    }
};
const siteMap = (state = initial, action) => {
    switch (action.type) {
        case 'SITEMAP':
            return state = action.data;
        default:

            return state;
    }
}

export default siteMap;